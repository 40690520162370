.character-display-body {
    background-color: #141414;
    align-items: center;
    padding-top: 80px;
}

.character-display-content {
    min-height: calc(100vh - 35px - 80px); /* 80px is the padding-top of .character-display-body */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .spacer {
    width: 10%;
}

.header .title {
    width: 80%;
    text-align: center;
    margin-top: 20px;
}

.header .title h1 {
    color: #F5F1E3;
}

.header .title h4 {
    color: #F5F1E3;
}

.header .actions {
    width: 10%;
    justify-content: flex-end;
}

.header .actions .edit-icon {
    color: #2660A4;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 1.2rem;
}

.button-container .rest {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    font-size: 1.2rem;
    background-color: #2660A4;
    border-color: #2660A4;
}

.edit {
    color: #2660a4;
    cursor: pointer;
}

.spellpoint-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
}

.resource-card-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
    /* styles for resource cards go here */
}

.add-resource-container {
    display: flex;
    justify-content: center;
    margin: 10px 0 20px;
}

.add-resource-button {
    background-color: #2660A4;
    border-color: #2660A4;
}

.no-resources {
    margin-top: 50px;
    color: #F5F1E3;
    text-align: center;
}

@media (max-width: 767px) {
    .resource-card {
        margin-bottom: 5px;
    }

    .header .title {
        margin-top: 10px;
    }
}