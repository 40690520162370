.profile-body {
    margin-top: 80px;
    padding: 1px 0;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
}

.profile-content {
    margin-top: 40px;
    flex: 1;
}

.profile-title {
    color:#F5F1E3;
    text-decoration: underline;
    margin-bottom: 35px;
}

.profile-button-row {
    margin-bottom: 20px;
}

.profile-button {
    padding: 10px;
    width: 100%;
    font-size: 1.2rem;
    background-color: #2660A4;
    border-color: #2660A4;
}

.change-modal {
    background-color: #F5F1E3;
}

.change-label {
    margin-top: 15px;
}