.site-nav{
    background-color: #333333;
    padding-left: 10px;
    font-size: 1.2rem;
    border-bottom-style:solid;
    border-bottom-color:#F5F1E3;
    border-bottom-width: 1px;
}

.site-nav-brand, .site-nav-brand:hover {
    color: #F5F1E3;
    padding: 0;
    user-select: none;
    cursor: default;
}

.custom-dropdown {
    margin-left: 20px;
}

.site-nav .nav-link {
    color: #F5F1E3; /* Set the desired text color */
}

.custom-dropdown .dropdown-menu {
    padding: 3px 0;
    background-color: #666666;
    border-color: #5C5C5C;
}

.custom-dropdown-item {
    padding: 7px 16px;
    color: #F5F1E3;
    background-color: #666666;
}

.custom-dropdown-item:hover {
    color: #F5F1E3;
    background-color: #5C5C5C;
}

.custom-divider {
    margin: 0;
    background-color: #5C5C5C;
}

.custom-navbar-toggle {
    margin-right: 40px;
}

.nav-options {
    margin-right: 40px;
}

.register-button {
    margin-left: 20px;
}