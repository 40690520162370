.resource-card {
    margin-bottom: 1.3rem;
    background-color: #141414;
    border-color: #333333;
    align-items: center;
}

.resource-card-title {
    font-size: 1.2rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #333333;
    width: 100%;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #F5F1E3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-text {
    margin: 0 5px;
    flex-grow: 1; /* Allows the title to take up all available space */
}

.edit-button-container {
    margin: 0 5px 0 0; /* Adjust as needed for spacing */
    display: flex;
}

.resource-card-body {
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resource-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resource-button-container {
    width: 7.5rem;
    height: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-button,
.circular-button:focus,
.circular-button:hover,
.circular-button:active,
.circular-button:disabled {
    margin: 0;
    padding: 0;
    border-radius: 50%;
    background-color: transparent;
    border: none;
}

.resource-delete-icon {
    font-size: medium;
    color:#B80000;
    cursor: pointer;
}

.edit-icon {
    color: #2660A4;
    cursor: pointer;
}

.edit-icon.minus {
    margin-right: .75rem;
}

.edit-icon.plus {
    margin-left: .75rem;
}

@media (max-width: 767px) {
    .edit-icon.minus {
        margin-right: .5rem;
    }

    .edit-icon.plus {
        margin-left: .5rem;
    }
}