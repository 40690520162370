.character-list-body {
    background-color: #141414;
    margin-top: 80px;
    padding: 1px 0;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
}

.character-list-content {
    margin-top: 50px;
    text-align: center;
    flex: 1;
}

.character-list-title {
    text-decoration: underline;
    color: #F5F1E3;
}