.edit.minus {
    margin-right: 1.25rem;
}

.edit.plus {
    margin-left: 1.25rem;
}

.custom-button {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: .97rem;
    background-color: #2660a4;
    transition: background-color 0.3s; /* smooth transition for background color */
}

.custom-button:hover {
    background-color: #1f4f8b; /* slightly darker blue for hover */
}

.spellpoint-card {
    margin-bottom: .5rem;
    background-color: #141414;
    border-color: #333333;
}

.spellpoint-title {
    font-size: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .3rem;
    background-color: #333333;
    width: 100%;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #F5F1E3;
}

.spellpoint-card-body {
    width: 100%;
    padding: 16px 0;
}

.spellpoint-card-body-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-pad-no-marg {
    margin: 0;
    padding: 0;
}

.spellpoint-button {
    margin: 0;
}

.center-flex {
    display: flex;
    justify-content: center;
}