.character-list-container {
    margin-top: 2rem;
}

.character-item,
.character-item:focus {
    background-color: #333333;
    border-color: #2660A4;
    color: #F5F1E3;
}

.character-item:hover,
.character-item:active {
    color: #F5F1E3;
    background-color: #2660A4;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon-action {
    cursor: pointer;
}

.update-action {
    margin-right: 25px;
}

.delete-icon {
    color: #B80000;
}

.add-character-button {
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: #2660a4;
    border-color: #2660a4;
}

.add-character-button:hover {
    background-color: #1f4f8b;
    border-color: #1f4f8b;
}